/* 
 * Copyright 2009-2019 Michael J. Simons.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react'
import PropTypes from 'prop-types'

// Renders a single member of the family
const Member = ({name, userOnDailyFratze, children}) => (
  <div className="col-xs-6 col-sm-3">
    <h3>{name}</h3>
    {userOnDailyFratze && 
      <div className="portrait">
        <a href={"https://dailyfratze.de/" + userOnDailyFratze}>
          <img src={"https://dailyfratze.de/fratzen/s/" + userOnDailyFratze + ".jpg"} alt={name} className="img-responsive img-thumbnail" />
        </a>
      </div>
    }
    {children}
  </div>  
)

Member.propTypes = {
  name: PropTypes.string,
  userOnDailyFratze: PropTypes.string,
  children: PropTypes.node.isRequired,
}


export default Member
